import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import images from '../../helpers/images';
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import helpers from '../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../parts/Loader';
 
function HomePage (){
    const [loading, setLoading] = useState(1);
    const [statistic, setStatistic] = useState([]);
    const navigate = useNavigate();

    // const [statistic, setStatistic] = useState({
    //     'created': 0,
    //     'in_progress': 0,
    //     'for_assessment': 0,
    //     'for_release': 0
    // });

    const [donorData, setDonor] = useState({
        salutation: "",
        first_name: "",
        last_name: "",
        news_letter: 0,
        email: "",
        mobile: "",
        birth_date: "",
        address: "",        
    });

    const [paymentData, setPayment] = useState({
        amount: 0,
        custom_amount: 0,
        payment_type: 1,
        payment_frequency: "",
    });

    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        //Mounted
        getDataList()
    }, []);
   
    // useEffect(() => {
        // let data = {
        //     'created': getTotal('created'),
        //     'in_progress': getTotal('in-progress'),
        //     'for_assessment': getTotal('for-assessment'),
        //     'for_release': getTotal('for-release'),
        //     'unit_checked': getTotal('unit-checked'),
        //     'completed': getTotal('completed'),
        //     'repair_completed': getTotal('repair-completed'),
        //     'cancelled': getTotal('cancelled'),
        //     'price_inquiry': getPriceInquiryTotal(),
        // }

        // setStatistic(data)
    // }, [service_request_list]);

    

    // function getTotal(status = ''){
    //     if(!status) return;

    //     const results = service_request_list.filter(obj => {
    //         return obj.status == status;
    //     });
        
    //       return results.length;
    // }

    // function getPriceInquiryTotal(status = ''){

    //     const results = service_request_list.filter(obj => {
    //         return obj.parts_price_inquiry === 1;
    //     });
        
    //       return results.length;
    // }

    function removeErrors(e){
        let { name } = e.target;
        let newErrors = {...errors}

        delete newErrors[name];

        setErrors(newErrors);
    }

    function getDataList(page = '', search_key = ''){
        setLoading(1)

        let filter = '?'
        if(page){
            filter += 'page='+page+'&'
        }
        if(search_key){
            filter += 'search='+search_key+'&'
        }

        const technician_id = helpers.getEmployeeID();
        if(technician_id){
            filter += 'technician_id='+technician_id+'&'
        }

        filter = filter.slice(0, -1)

        const url = helpers.getUrl('admin/service-request/statistic'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setStatistic(res)
                // setAllData(res)
                setLoading(0)
                // table_items(res.data)

                //const data = res.data.filter(item => item.status == 'created');
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function linkTo(params = ''){
        let page = 'service-request'

        if(!helpers.checkPermissions(["service-request-dashboard"])){
            page = 'technician-service-request'
        }

        navigate(page + params);
    }

    return (
        <>
        <MainContainer page_title="Welcome to ServhiceHub" menu="home" breadcrumbs={false}>
                <div className="content">
                    <div className="container-fluid" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        {/* <h3>Welcome to ServhiceHub</h3> */}

                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-12">
                                {/* <Link className='info-box text-dark' to={linkTo("/service-request?status=created")}> */}
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=created")}>
                                    <span className="info-box-icon bg-primary"><i className="far fa-envelope" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">New Request</span>
                                        <h3 className="info-box-number mb-0">{statistic.created}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=for-assessment")}>
                                    <span className="info-box-icon bg-info"><i className="fas fa-list-ul" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">For Assessment</span>
                                        <h3 className="info-box-number mb-0">{statistic.for_assessment}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=in-progress")}>
                                    <span className="info-box-icon bg-warning"><i className="fas fa-hourglass-half" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">In Progress</span>
                                        <h3 className="info-box-number mb-0">{statistic.in_progress}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=unit-checked")}>
                                    <span className="info-box-icon bg-info"><i className="far fa-calendar-check" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Unit Checked</span>
                                        <h3 className="info-box-number mb-0">{statistic.unit_checked}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=price-inquiry")}>
                                    <span className="info-box-icon bg-secondary"><i className="fas fa-file-invoice-dollar" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Price Inquiry</span>
                                        <h3 className="info-box-number mb-0">{statistic.price_inquiry}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=repair-completed")}>
                                    <span className="info-box-icon bg-success"><i className="fas fa-tasks" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Repair Completed</span>
                                        <h3 className="info-box-number mb-0">{statistic.repair_completed}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=for-release")}>
                                    <span className="info-box-icon bg-success"><i className="fas fa-share-square" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Ready For Release</span>
                                        <h3 className="info-box-number mb-0">{statistic.for_release}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=completed")}>
                                    <span className="info-box-icon bg-success"><i className="fas fa-check-circle" /></span> 
                                    <div className="info-box-content">
                                        <span className="info-box-text">Completed</span>
                                        <h3 className="info-box-number mb-0">{statistic.completed}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=return-to-owner")}>
                                    <span className="info-box-icon bg-danger"><i className="fas fa-redo" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Return To Owner</span>
                                        <h3 className="info-box-number mb-0">{statistic.return_to_owner ? statistic.return_to_owner : "0"}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("?status=cancelled")}>
                                    <span className="info-box-icon bg-danger"><i className="far fa-calendar-times" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Cancelled</span>
                                        <h3 className="info-box-number mb-0">{statistic.cancelled}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>{/* /.container-fluid */}
                </div>

        </MainContainer>
        </>
    )
}
 
export default HomePage;