import React, { useEffect, useState } from 'react';
import images from '../../helpers/images';
 
function ThankYou (){
    
    useEffect(() => {
        //Mounted
    }, []);

    return (
        <>
            <div className="wrapper-holder" id="childhood-rescue-donate-thank-you">
            <section className="page-content thankyou-banner">
                <div className="container">
                <div className="row">
                    <img src={images.donateBanner} />
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-md-offset-2 text">
                    <h2>Hi, First Name!</h2>
                    <p>Thank you for supporting World Vision's Childhood Rescue in helping children reclaim their childhood! Let's keep on helping them to survive, recover, and build a future.</p>
                    </div>
                </div>
                </div>
            </section>
            </div>

        </>
    )
}
 
export default ThankYou;