import React, { useEffect, useState } from 'react';
import { Outlet, Link,  useParams, useNavigate  } from "react-router-dom";
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
import InputText from '../../elements/InputText'
import InputTextArea from '../../elements/InputTextArea'
import DropDownSelection from '../../elements/DropDownSelection';
import Radio from '../../elements/InputRadio';
import InputFile from '../../elements/InputFile';
import Checkbox from '../../elements/InputCheckbox';
import Loader from '../../parts/Loader';
import InputNumber from '../../elements/InputNumber';
import RenderErrors from '../../elements/RenderErrors';
import store from '../../../helpers/store';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders, httpFormRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
 
function EmployeeManagementForm ({readonly = false}){
    let { id } = useParams();
    const [form_data, setForm] = useState(store.employee_data);
    const [form_title, SetTitle] = useState('');
    const [branch_list, setBranches] = useState([]);
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        getBranches();

        //Mounted
        if(id){
            getData(id)
            SetTitle('Update Employee Info')
        }
        else{
            SetTitle('Create New Employee')
        }
    }, []);

    function getBranches(){
        const url = helpers.getUrl('admin/branches')
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setBranches(res.data)

                setLoading(0)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    //title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function getData(id){
        const url = helpers.getUrl('admin/employees/' + id)
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {

                setForm({
                    'branch_id': res.branch_id, 
                    'first_name': res.first_name, 
                    'last_name': res.last_name, 
                    'position': res.position,
                    'status': res.status,
                 })

                setLoading(0)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    //title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }

    function submit(e){
        let url = helpers.getUrl('admin/employees')

        if(id){
            url = helpers.getUrl('admin/employees/'+id)
        }

        setLoading(1)

        httpRequest(url, "POST", {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let return_url = '/manage/employees'
                let new_id = ''
                if(res.hasOwnProperty("data")){
                    new_id = res.data.id;
                }
                if(new_id){
                    return_url += '/' + new_id + '/edit'
                }
                else if(id){
                    return_url += '/' + id + '/edit'
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        return navigate(return_url);
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }
                
                // //Unauthorize
                // if(err.status_code === 401){
                //     window.location.href = "/";
                // }

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    return (
        <MainContainer page_title={form_title} menu="manage-employees">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="col-sm-10 col-md-8">
                        <form className="card">
                            <div className="card-body">
                                    <div className="row">
                                        <div className="col-8 mb-3">
                                            <p className='mb-0'>Employee Information</p>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="first_name" 
                                                name="first_name" 
                                                value={form_data.first_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="First Name"
                                                type="text"
                                                label={<>First Name <span className="text-danger">*</span></>}
                                                //readOnly={form_data.employee_id}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="first_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="last_name" 
                                                name="last_name" 
                                                value={form_data.last_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Last Name"
                                                type="text"
                                                label={<>Last Name <span className="text-danger">*</span></>}
                                                //readOnly={form_data.employee_id}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="last_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="position" 
                                                name="position" 
                                                value={form_data.position}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Position"
                                                type="position"
                                                label={<>Position <span className="text-danger">*</span></>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="position" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <DropDownSelection
                                                tagClass="form-control"
                                                name='branch_id' 
                                                placeholder='Select Branch'
                                                label={<>Branch <span className="text-danger">*</span></>}
                                                value={form_data.branch_id}  
                                                onChange={inputChange}
                                                optionList={branch_list}
                                                optionProps ={{value:'id', label:'name'}}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="branch_id" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-12"></div>
                                        <div className="col-sm-6 col-md-4 form-group">
                                            <div className="form-check" style={{padding: '0.5rem', paddingLeft: '1.5rem'}}>
                                                <Checkbox
                                                    id="status"
                                                    name="status"
                                                    value={form_data.status}
                                                    label='Active'
                                                    labelClass="form-check-label"
                                                    tagClass="form-check-input"
                                                    onChange={inputChange}
                                                    checked = {form_data.status}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                            <div className="card-footer text-right">   
                                    <Link className='mr-3' to="/manage/employees"><i className="fas fa-arrow-left mr-3" />Back</Link>
                                    <button type='button' className='btn btn-success btn-sm' onClick={submit}><i className="fas fa-save mr-2" />{id ? 'Update' : 'Save'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default EmployeeManagementForm;