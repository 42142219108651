import React, { useEffect, useState } from 'react';
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
 
function ServiceRequestReport (){

    return (
        <MainContainer page_title="Service Request Reports" menu="reports-serivce-request">
            <div className="container-fluid">
                <h4>Extract Report</h4>
            </div>
        </MainContainer>
    )
}
 
export default ServiceRequestReport;