import React, { useEffect, useState } from 'react';
import { useNavigate  } from "react-router-dom";
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import InputText from '../elements/InputText';
import Loader from '../parts/Loader';
import store from '../../helpers/store';
import RenderErrors from '../elements/RenderErrors';
import helpers from '../../helpers/helpers';
import { httpRequest } from '../../helpers/httpService';
import Swal from 'sweetalert2';
import appSession from '../../helpers/session';
 
function ProfilePage (){
    const [form_data, setForm] = useState(store.user_data);
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    let navigate = useNavigate();
    const id = helpers.getUserID();
    const name = helpers.getUserName();
    
    useEffect(() => {
        //Mounted
        getData(id)
    }, []);

    function getData(id){
        const url = helpers.getUrl('admin/users/' + id)
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {

                const data = {
                    'employee_id': res.employee_id, 
                    'name': res.name, 
                    'email': res.email, 
                    'password': '',
                    'confirm_password': '',
                    'status': res.status,
                    'role_id': res.role_id
                 }

                setForm(data)

                setLoading(0)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    //title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }

    function submit(e){
        let url = helpers.getUrl('admin/users')

        if(id){
            url = helpers.getUrl('admin/users/'+id)
        }

        setLoading(1)

        httpRequest(url, "POST", {
            postdata: form_data,
        }, true)
            .then((res) => {
                appSession.setSessionItem("jma-servicehub-name", form_data.name)
                setLoading(0)

                let msg = res.message
                let title = "Success"
                if(form_data.password && form_data.confirm_password){
                    msg = 'Password has been updated. Your account will be signed-out.'
                    title = ''
                }

                Swal.fire({
                    title: title,
                    text: msg,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        if(form_data.password && form_data.confirm_password){
                            appSession.logout()
                        }
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }
    
    return (
        <MainContainer menu="profile" page_title="Profile">
            <div className="container">
                <div className="row d-flex justify-content-center" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="col-sm-10 col-md-8">
                        <form className="row">
                            <div className="col-md-6 form-group">
                                <InputText
                                    id="name" 
                                    name="name" 
                                    value={form_data.name}
                                    tagClass="form-control mb-0"
                                    onChange={inputChange}
                                    placeholder="Display Name"
                                    type="text"
                                    label={<>Display Name <span className="text-danger">*</span></>}
                                    //readOnly={form_data.employee_id}
                                />
                                <RenderErrors 
                                    errors={errors} 
                                    name="name" 
                                    className="text-danger"
                                    style={{fontSize: '.9rem'}}
                                />
                            </div>
                            <div className="col-12"><small>Note: Leave Password &amp; Confirm Password blank if not need to update.</small></div>
                            <div className="col-md-6 form-group">
                                <InputText
                                    id="password" 
                                    name="password" 
                                    value={form_data.password}
                                    tagClass="form-control mb-0"
                                    onChange={inputChange}
                                    placeholder="Password"
                                    type="password"
                                    label={<>Password <span className="text-danger">*</span></>}
                                />
                                <RenderErrors 
                                    errors={errors} 
                                    name="password" 
                                    className="text-danger"
                                    style={{fontSize: '.9rem'}}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <InputText
                                    id="confirm_password" 
                                    name="confirm_password" 
                                    value={form_data.confirm_password}
                                    tagClass="form-control mb-0"
                                    onChange={inputChange}
                                    placeholder="Confirm Password"
                                    type="password"
                                    label={<>Comfirm Passowrd <span className="text-danger">*</span></>}
                                />
                                <RenderErrors 
                                    errors={errors} 
                                    name="confirm_password" 
                                    className="text-danger"
                                    style={{fontSize: '.9rem'}}
                                />
                            </div>
                            <div className="col-12 form-group">
                                    <button type='button' className='btn btn-success btn-sm' onClick={submit}><i className="fas fa-save mr-2" />Update</button>
                            </div>
                            

                        </form>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default ProfilePage;