import React from 'react';
import { Outlet, Link } from "react-router-dom";
import images from '../../helpers/images';

import appSession from '../../helpers/session';

import helpers from '../../helpers/helpers';

function Header({menu}){

    function setCurrentPage(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-link'

        if(menu_id.includes(current_page)){
            classes += ' active '

            if(parent_menu){
                classes += 'bg-info'
            }
            else{
                classes += 'bg-secondary'
            }
        }

        return classes;
    }

    function setMenuOpen(menu_id = [], current_page = ''){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-open'
        }
        return classes;
    }

    return (
        <>

            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                    </ul>
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    <li className={menu == 'profile' ? "nav-item active text-danger" : "nav-item"}>
                        <Link className="nav-link" to="/profile"><i className="fas fa-user mr-2" /> {helpers.getUserName()}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" data-widget="control-sidebar" data-slide="true" onClick={() => appSession.logout()}><i className="fas fa-sign-out-alt"></i> Logout</Link>
                    </li>
                </ul>
            </nav>

            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-light-primary elevation-4">
                {/* Brand Logo */}
                <Link className="brand-link text-center" to="/">
                    {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} /> */}
                    <span className="brand-text font-weight-light"><img src={images.mainLogo} alt="Logo" width={200} /></span>
                </Link>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link className={setCurrentPage(['home'], menu, true)} to="/">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>Dashboard</p>
                            </Link>
                        </li>
                        {
                            helpers.checkPermissions(["all-access", "service-request-dashboard"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['service-request-main'], menu, true)} to="/service-request">
                                    <i className="nav-icon fas fa-tasks" />
                                    <p>Service Request</p>
                                </Link>
                            </li> : ""
                        }
                        {
                            helpers.checkPermissions(["all-access", "technician-dashboard"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['service-request-technician'], menu, true)} to="/technician-service-request">
                                    <i className="nav-icon fas fa-tools" />
                                    <p>Technician Page</p>
                                </Link>
                            </li> : ""
                        }
                        {
                            helpers.checkPermissions(["all-access", "manage-users"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['manage-users'], menu, true)} to="/manage/user-account">
                                    <i className="nav-icon fas fa-user" />
                                    <p>User Account</p>
                                </Link>
                            </li> : ""
                        }
                        {
                            helpers.checkPermissions(["all-access", "manage-employees"]) ? 
                            <li className="nav-item">
                                <Link className={setCurrentPage(['manage-employees'], menu, true)} to="/manage/employees">
                                    <i className="nav-icon fas fa-users" />
                                    <p>Employees</p>
                                </Link>
                            </li> : ""
                        }
                    </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>

            <Outlet />
        </>
    )
}

export default Header;