/**
 * Input Text Reusable Components
 */
import React from "react";

const InputCheckbox = ({
        id,
        name, 
        value, 
        label='', 
        tagClass='',
        labelClass='',
        placeholder = '', 
        onChange,
        onFocus,
        checked = 0,
        readOnly = false
    }) => {
        if (!id) id = name;

    return (
        <>
            <label htmlFor={id} className={labelClass}>
                <input
                    id={id}
                    name={name}
                    className={tagClass}
                    type="checkbox"
                    value={value|| ''}
                    checked={checked}
                    onChange={onChange}
                    onFocus={onFocus}
                    readOnly={readOnly}
                /> {label}
            </label>
        </>
    );
};

export default InputCheckbox;
