import React, { useEffect, useState, useRef } from 'react';
import { Outlet, Link,  useParams, useNavigate  } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import InputText from '../../elements/InputText'
import Loader from '../../parts/Loader';
import InputNumber from '../../elements/InputNumber';
import RenderErrors from '../../elements/RenderErrors';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders, httpFormRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import '../../../assets/css/signature-canvas.css'
 
function ReleaseFormView ({action = false}){
    
    let { service_id } = useParams();
    const [form_data, setForm] = useState({
        'receiver': '',
        'released_date': '',
        'signature': ''
    });
    //const [employees, setEmployees] = useState({});
    const [form_title, SetTitle] = useState('');
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    let navigate = useNavigate();

    const sigRef = useRef();
    const [signature, setSignature] = useState(null);

    useEffect(() => {
        SetTitle('Release Item')
        getData(service_id)
    }, []);

    function getData(service_id){
        const url = helpers.getUrl('admin/service-request/'+service_id+'/schedule')
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setLoading(0)
                if(res.data){
                    setForm({ ...form_data, ...res.data });
                }
            })
            .catch((err) => {
                //getEmployee()

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }
    
    return (
        <MainContainer page_title="View Released Form" menu="service-request-main">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="col-md-12 col-lg-6">
                        <form className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 mb-3">Service No:&nbsp;&nbsp;<Link title='Update User' to={`/service-request/${form_data.id}/details`}>{form_data.service_no}&nbsp;&nbsp;<i className="fas fa-external-link-alt" /></Link></div>
                                    <div className="col-md-12 col-lg-6 form-group">
                                            <InputText
                                                id="receiver" 
                                                name="receiver" 
                                                value={form_data.receiver}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Full Name"
                                                type="text"
                                                label={<>Receiver Name</>}
                                                readOnly
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="receiver" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                    </div>
                                    <div className="col-md-12 col-lg-6 form-group">
                                        <InputText
                                            id="released_date" 
                                            name="released_date" 
                                            value={form_data.released_date}
                                            tagClass="form-control mb-0"
                                            onChange={inputChange}
                                            placeholder="Date"
                                            type="date"
                                            label={<>Received Date</>}
                                            readOnly
                                        />
                                        <RenderErrors 
                                            errors={errors} 
                                            name="released_date" 
                                            className="text-danger"
                                            style={{fontSize: '.9rem'}}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>Signature</label><br/>
                                        <img src={form_data.signature} width={200} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">                       
                                <Link className='mr-3' to="/service-request"><i className="fas fa-arrow-left mr-3" />Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default ReleaseFormView;