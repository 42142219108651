/**
 * Input Text Reusable Components
 */
import React from "react";

const InputRadio = ({
        id,
        name, 
        value, 
        label='', 
        tagClass='',
        labelClass='',
        placeholder = '', 
        onChange,
        onFocus,
        checked = false,
        readOnly = false
    }) => {
        //let styles = `focus:outline-none form-radio h-4 w-4 text-${dotColor} transition duration-150 ease-in-out`;
        //if (checked) styles += " active";
        if (!id) id = name;

    return (
        <>
            <label htmlFor={id} className={labelClass}>
                <input
                    id={id}
                    name={name}
                    type="radio"
                    value={value|| ''}
                    checked={checked}
                    onChange={onChange}
                    readOnly={readOnly}
                /> {label}
            </label>
        </>
    );
};

export default InputRadio;
