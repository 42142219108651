import React, { useEffect } from 'react';
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import { Outlet, Link } from "react-router-dom";
 
function Page404 (){
    
    useEffect(() => {
        //Mounted
    }, []);
    
    return (
        <MainContainer style={{minHeight: '1604.8px'}} breadcrumbs={false}>

            
            <section className="content">
                <div className="error-page d-flex align-items-center">
                    <h2 className="headline text-danger"> 404</h2>
                    <div className="error-content ml-3">
                        <h2><i className="fas fa-exclamation-triangle text-danger" /> Page not found.</h2>
                        <h5 className='mb-0'>
                        We could not find the page you were looking for.
                        Meanwhile, you may <Link to="/">return to dashboard</Link>.
                        </h5>
                        {/* <form className="search-form">
                        <div className="input-group">
                            <input type="text" name="search" className="form-control" placeholder="Search" />
                            <div className="input-group-append">
                            <button type="submit" name="submit" className="btn btn-warning"><i className="fas fa-search" />
                            </button>
                            </div>
                        </div>
                        </form> */}
                    </div>
                </div>
            </section>

        </MainContainer>
    )
}
 
export default Page404;