/**
 * Input Text Reusable Components
 */
import React from "react";

const InputFile = ({
        id,
        name, 
        value, 
        label='', 
        tagClass='',
        labelClass='',
        placeholder = '', 
        onChange,
        onFocus,
        type = 'text',
        accept=''
    }) => {

    return (
        <>

            {
            label && <label htmlFor={id} className={labelClass}>{label}</label>
            }
            <input
                id={id}
                value={value|| ''}
                onKeyUp={onFocus}
                onChange={(e) => onChange(e)}
                className={tagClass}
                type={type}
                name={name}
                placeholder={placeholder}
                accept={accept}
            />
        </>
    );
};

export default InputFile;
