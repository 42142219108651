import React, { useEffect, useState , useRef} from 'react';
import { Outlet, Link,  useParams  } from "react-router-dom";
import JobOrderInfo from './JobOrderInfo';
 
function JobOrderPrintView (){
    let { service_id } = useParams();
    let componentRef = useRef();

    return (
        <JobOrderInfo service_id={service_id} action="print" ref={(el) => (componentRef = el)} />
    )
}
 
export default JobOrderPrintView;