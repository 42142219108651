import React, { useEffect } from 'react';
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import { Outlet, Link } from "react-router-dom";
import appSession from '../../helpers/session';
 
function DeniedPage (){
    
    useEffect(() => {
        //Mounted
    }, []);
    
    return (
        <MainContainer style={{minHeight: '1604.8px'}} breadcrumbs={false}>

            
            <section className="content">
                <div className="error-page d-flex align-items-center">
                    <h2 className="headline text-danger"> 403</h2>
                    <div className="error-content ml-3">
                        <h2><i className="fas fa-times-circle text-danger" /> Access is Denied.</h2>
                        <h5 className='mb-0'>
                        You do not have permission to view this page. Please check your credential and try again. Click to <Link onClick={() => appSession.logout()}>Logout</Link>.
                        </h5>
                    </div>
                </div>
            </section>

        </MainContainer>
    )
}
 
export default DeniedPage;