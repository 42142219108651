import React, { useEffect } from 'react';
import ContentHeader from '../parts/ContentHeader';
 
function StarterTemplate (){
    
    useEffect(() => {
        //Mounted
    }, []);
    
    return (
        <div className="content-wrapper">
            <ContentHeader title="Starter Page" />
            
            <section className="content">
                <div className="container-fluid">
                    <h3>Content Here...</h3>
                </div>
            </section>

        </div>
    )
}
 
export default StarterTemplate;