import React, { useEffect, useState, useRef } from 'react';
import { Outlet, Link,  useParams, useNavigate  } from "react-router-dom";
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
import InputText from '../../elements/InputText'
import InputTextArea from '../../elements/InputTextArea'
import DropDownSelection from '../../elements/DropDownSelection';
import Radio from '../../elements/InputRadio';
import InputFile from '../../elements/InputFile';
import Checkbox from '../../elements/InputCheckbox';
import Loader from '../../parts/Loader';
import InputNumber from '../../elements/InputNumber';
import RenderErrors from '../../elements/RenderErrors';
import store from '../../../helpers/store';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders, httpFormRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import SignatureCanvas from 'react-signature-canvas';
import '../../../assets/css/signature-canvas.css'
 
function ReleaseForm ({action = false}){
    
    let { service_id } = useParams();
    const [form_data, setForm] = useState({
        'receiver': '',
        'released_date': '',
        'signature': ''
    });
    //const [employees, setEmployees] = useState({});
    const [form_title, SetTitle] = useState('');
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    let navigate = useNavigate();

    const sigRef = useRef();
    const [signature, setSignature] = useState(null);
    const handleSignatureEnd = () => {
        const data = sigRef.current.toDataURL()

        setSignature(data);

        let new_state = { "signature": data }

        setForm({ ...form_data, ...new_state });
    }
    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    }
    const [signature_output, setOutput] = useState('');
    
    useEffect(() => {
        setOutput(<img className='img img-fluid' src={signature} width={100} />)
    }, [signature]);

    useEffect(() => {
        SetTitle('Release Item')
        getData(service_id)
    }, []);

    function getData(service_id){
        const url = helpers.getUrl('admin/service-request/'+service_id+'/schedule')
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setLoading(0)
                if(res.data){
                    // const form_key =[
                    //     'service_id', 
                    //     'technician_id',
                    //     'extra_info',
                    //     'schedule_type',
                    //     'assessment',
                    //     'schedule_date',
                    //     'assessed_date'
                    // ]

                    // const filtered = Object.keys(res.data)
                    //                 .filter(key => form_key.includes(key))
                    //                 .reduce((obj, key) => {
                    //                 obj[key] = res.data[key];
                    //                 return obj;
                    //                 }, {});

                    // setForm({ ...form_data, ...filtered })
    
                    let title = 'Release Item: ';

                    SetTitle(`${title} ${res.data.service_no}`)
                }
            })
            .catch((err) => {
                //getEmployee()

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }

    

    function getFormData(status = 'draft'){
        let form = new FormData();

        for(var key in form_data) {
            form.append(key, form_data[key]);
        }

        return form
    }

    function getFileName(key = ''){
        let filename = ''
        if(form_data[key]){
            filename = form_data[key].name
        }

        return filename
    }

    function displayImage($url = '', $alt =''){
        if($url && service_id){
            return <><img src={$url} width={50} className='img-thumbnail' alt={$alt}/><br/><small>Preview</small></>
        }
    }


    function submit(e){
        const formData = getFormData()

        let url = helpers.getUrl('admin/service-request/'+service_id+'/release')

        setLoading(1)

        httpRequest(url, "POST", {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)
                setErrors([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        //window.location.reload(false);
                        return navigate("/service-request");
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }
                
                // //Unauthorize
                // if(err.status_code === 401){
                //     window.location.href = "/";
                // }

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function isWarrantyCheck(status = ''){
        if(status == 'active') return true;

        return false;
    }
    
    return (
        <MainContainer page_title={form_title} menu="service-request-main">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="col-sm-10 col-md-8">
                        <form className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 mb-3">Details</div>
                                    <div className="col-md-4 form-group">
                                            <InputText
                                                id="receiver" 
                                                name="receiver" 
                                                value={form_data.receiver}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Full Name"
                                                type="text"
                                                label={<>Receiver Name<span className='text-danger'>*</span></>}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="receiver" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <InputText
                                            id="released_date" 
                                            name="released_date" 
                                            value={form_data.released_date}
                                            tagClass="form-control mb-0"
                                            onChange={inputChange}
                                            placeholder="Date"
                                            type="date"
                                            label={<>Released Date<span className='text-danger'>*</span></>}
                                        />
                                        <RenderErrors 
                                            errors={errors} 
                                            name="released_date" 
                                            className="text-danger"
                                            style={{fontSize: '.9rem'}}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 form-group mb-2" style={{fontSize: '.9rem'}}>
                                        By signing below, I acknowledge the following:<br/>
                                        1. Unit tested in my presence and acknowledge unit is good working condition<br/>
                                        2. JMA Technician/staff explained to me the repair done and the related warranty for these repairs (1 month warranty for the same issue).
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-md-12">
                                        {signature_output}
                                    </div> */}
                                    <div className="col-md-12">
                                        <label>Signature<span className='text-danger'>*</span></label>
                                        <SignatureCanvas 
                                        penColor="black"
                                        canvasProps={{className: 'signature'}}
                                        ref={sigRef}
                                        onEnd={handleSignatureEnd}
                                        />
                                        <RenderErrors 
                                            errors={errors} 
                                            name="signature" 
                                            className="text-danger"
                                            style={{fontSize: '.9rem'}}
                                        />
                                        <button type='button' className='btn btn-secondary mt-2 btn-sm' onClick={clearSignature}>Clear</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">                       
                                <Link className='mr-3' to="/service-request"><i className="fas fa-arrow-left mr-3" />Cancel</Link>
                                <button type='button' className='btn btn-success btn-sm' onClick={submit}><i className="fas fa-save mr-2" />Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default ReleaseForm;