import * as config from '../config/env';
import appConfig from '../config/env';

/**
 *
 * the session key
 */
function sessionKey() {
    return `${appConfig.prefix}_session`;
}

/**
 *
 * check the session and return its value if it exist
 */
function getSessionKey() {
    const key = sessionKey();
    return localStorage.getItem(key);
}

/**
 *
 * get session token object
 */
function getSessionTokenObj() {
    return { session_key: getSessionKey() };
}

/**
 *
 * set the session key val
 */
function setSessionToken(value) {
    const key = sessionKey();

    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
    }

    localStorage.setItem(key, value);
}

/**
 *
 * set the session key val
 */
 function setSessionItem(key, value) {

    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
    }

    localStorage.setItem(key, value);
}

/**
 *
 * set the session key val
 */
function resetFormSession() {
    if (localStorage.getItem(sessionKey())) {
        localStorage.removeItem(sessionKey());
    }
}


    
/**
 * 
 * Set session variables after successful login
 * 
 * @param {string} accessToken 
 * @param {number} expiresAt 
 * @param {string} name 
 */
function setSession(access_token, permissions, user) {
    setSessionItem("jma-servicehub-token", access_token)
    setSessionItem("jma-servicehub-permissions", permissions)
    setSessionItem("jma-servicehub-name", user.name)
    setSessionItem("jma-servicehub-uid", user.id)
    setSessionItem("jma-servicehub-eid", user.employee_id)
    setSessionItem("jma-servicehub-authenticated", "true")

    if (localStorage.getItem(sessionKey())) {
        localStorage.removeItem(sessionKey());
    }
}



const logout = () => {
    if (localStorage.getItem('jma-servicehub-token')) {
        localStorage.removeItem('jma-servicehub-token');
    }

    if (localStorage.getItem('jma-servicehub-permissions')) {
        localStorage.removeItem('jma-servicehub-permissions');
    }

    if (localStorage.getItem('jma-servicehub-name')) {
        localStorage.removeItem('jma-servicehub-name');
    }

    if (localStorage.getItem('jma-servicehub-uid')) {
        localStorage.removeItem('jma-servicehub-uid');
    }

    if (localStorage.getItem('jma-servicehub-eid')) {
        localStorage.removeItem('jma-servicehub-eid');
    }

    if (localStorage.getItem('jma-servicehub-authenticated')) {
        localStorage.removeItem('jma-servicehub-authenticated');
    }

    if (localStorage.getItem(sessionKey())) {
        localStorage.removeItem(sessionKey());
    }

    window.location.href = '/';
}


const appSession = {
    sessionKey,
    getSessionKey,
    getSessionTokenObj,
    setSessionToken,
    logout,
    setSession,
    resetFormSession,
    setSessionItem
}

export default appSession;