import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

/** Component Pages Here */
import HomePage from '../components/pages/HomePage';
import OtherPage from '../components/pages/OtherPage';
import ThankYou from '../components/pages/ThankYou';
import LoginPage from '../components/pages/LoginPage';
import Header from '../components/parts/Header';
import Footer from '../components/parts/Footer';
import Page404 from '../components/pages/Page404';
import StarterTemplate from '../components/pages/StarterTemplate';
import ProfilePage from '../components/pages/ProfilePage';
import DeniedPage from '../components/pages/DeniedPage';


import ServiceRequestDashboard from '../components/pages/service-request/ServiceRequestDashboard';
import ServiceRequestForm from '../components/pages/service-request/ServiceRequestForm';
import ServiceScheduleForm from '../components/pages/service-request/ServiceScheduleForm';
import ReleaseForm from '../components/pages/service-request/ReleaseForm';

// Management
import UserManagement from '../components/pages/management/UserManagement';
import UserManagementForm from '../components/pages/management/UserManagementForm';
import EmployeeManagement from '../components/pages/management/EmployeeManagement';
import EmployeegementForm from '../components/pages/management/EmployeeManagementForm';

import ServiceRequestReport from '../components/pages/reports/ServiceRequestReport';
import JobOrderViewer from '../components/pages/reports/JobOrderViewer';
import JobOrderPrintView from '../components/pages/reports/JobOrderPrintView';
import ReleaseFormView from '../components/pages/reports/ReleaseFormView';

import helpers from '../helpers/helpers';

const Protected = ({ isLoggedIn, allowedPermissions=[],  children, requestor='' }) => {
    const is_authenticated = helpers.isAuthenticated();
    const authorized = helpers.checkPermissions(allowedPermissions);

    if (!is_authenticated) {
        return <Navigate replace to="/login" />
    }
    else if(allowedPermissions.length && !authorized){
        //return <Navigate replace to="/" />
        return <DeniedPage />
    }
    
    return children;
};

function MainRoutes(){
    return (
        <>
        <Routes>
            <Route 
                exact path="/"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <HomePage />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                        <ServiceRequestDashboard />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/new"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                        <ServiceRequestForm />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/:service_id/edit"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-update"]}>
                        <ServiceRequestForm />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/:service_id/details"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard", "technician-dashboard"]}>
                        <ServiceRequestForm readonly={true} />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/:service_id/new-schedule"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-schedule-create"]}>
                        <ServiceScheduleForm action="create-schedule" />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/:service_id/edit-schedule"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-schedule-update"]}>
                        <ServiceScheduleForm action="update-schedule" />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/:service_id/add-assessment"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-schedule-create"]}>
                        <ServiceScheduleForm action="create-assessment" />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/:service_id/edit-assessment"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-schedule-update"]}>
                        <ServiceScheduleForm action="update-assessment" />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/service-request/:service_id/release"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-schedule-update"]}>
                        <ReleaseForm />
                    </Protected>
                }
            />
            
            {/* Technician Dashboard */}
            <Route 
                exact path="/technician-service-request"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "technician-dashboard"]}>
                        <ServiceRequestDashboard viewer="technician" menu="service-request-technician" page_title="Assigned Service Request" />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/profile"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <ProfilePage />
                    </Protected>
                }
            />

             
            {/* Management */}
            
            <Route 
                exact path="/manage/user-account"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "manage-users"]}>
                        <UserManagement />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/manage/user-account/new"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "manage-users"]}>
                        <UserManagementForm />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/manage/user-account/:id/edit"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "manage-users"]}>
                        <UserManagementForm />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/manage/employees"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "manage-employees"]}>
                        <EmployeeManagement />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/manage/employees/new"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "manage-employees"]}>
                        <EmployeegementForm />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/manage/employees/:id/edit"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "manage-employees"]}>
                        <EmployeegementForm />
                    </Protected>
                }
            />

            {/* End of Management Routes */}
            
            {/* Export */}

            <Route 
                exact path="/export/job-order/:service_id/view"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <JobOrderViewer page_title="Job Order" />
                    </Protected>
                }
            />

            <Route 
                exact path="/export/job-order/:service_id/print-view"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <JobOrderPrintView />
                    </Protected>
                }
            />

            <Route 
                exact path="/export/service-request/:service_id/view"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <JobOrderViewer page_title="View Service Request" />
                    </Protected>
                }
            />

            <Route 
                exact path="/export/service-request/:service_id/print-view"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <JobOrderPrintView />
                    </Protected>
                }
            />

            <Route 
                exact path="/export/released-form/:service_id/view"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        {/* <JobOrderViewer page_title="View Service Request" /> */}
                        <ReleaseFormView />
                    </Protected>
                }
            />

            {/* Reports */}
            
            <Route 
                exact path="/reports/service-request"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                        <ServiceRequestReport />
                    </Protected>
                }
            />
            
            <Route 
                exact path="/reports/job-order"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                        <JobOrderViewer />
                    </Protected>
                }
            />

            <Route 
                exact path="/login" 
                element={<LoginPage />} 
            />

            {/* <Route 
                path="*" 
                element={<Page404 />} 
            /> */}
            <Route 
                path="*" 
                element={<Page404 />} 
            />
        </Routes>
        </>
    )
}

export default MainRoutes;
