import React, { useEffect, useState } from 'react';
//const $ = require( "jquery" );
/** 
 * Required css & jss assett
 */
import './assets/css/adminlte.min.css';
import './assets/vendor/fontawesome-free/css/all.min.css' //
import './assets/css/custom.css'
//css
// import './assets/css/childhood-rescue-donate.css';//css
// import './assets/js/main.js';
// import './assets/js/adminlte/adminlte.js';
/**
 * Parts

/**
 * Main Router
*/
import MainRoutes from './routes/Routes';

function App() {
  return (
    <div className="App wrapper">
      <MainRoutes />
    </div>
  );
}

export default App;
