import React, { useEffect, useState } from 'react';
import { Outlet, Link,  useParams  } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';

import JobOrderInfo from './JobOrderInfo';
 
function JobOrderViewer ({page_title = 'Job Order'}){
    let { service_id } = useParams();

    return (
        <MainContainer page_title={page_title} menu="reports-job-order">
            <div className="container-fluid">
                <JobOrderInfo service_id={service_id} className="pb-3" />
            </div>
        </MainContainer>
    )
}
 
export default JobOrderViewer;