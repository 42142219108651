const baseUrl = process.env.REACT_APP_API_BASE_URL;
let apiUrl = '';
const recaptchakey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const appToken = process.env.REACT_APP_MODULE_TOKEN;
const prefix = 'wv_events'
const base_root = window.location.host

if (base_root === "servicehub-admin.jma-electronics.com") {
      apiUrl = process.env.REACT_APP_API_PROD_URL;
}
else{
      apiUrl = process.env.REACT_APP_API_STAGING_URL;
}

const appConfig = {
      baseUrl,
      apiUrl,
      recaptchakey,
      prefix,
      appToken
}

export default appConfig;