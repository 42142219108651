// import moment from 'moment';
import images from './images';
import appConfig from '../config/env';
import { Link } from "react-router-dom";


// function displayDateTime(data){
//     let connector = '-'
//     let seperator = ','
//     let event_date = ''

//     let start_date = moment(data.start_date);
//     let end_date = moment(data.end_date);

//     let start_time = data.start_time;
//     let end_time = data.end_time;

//     let start_month = start_date.format('M');
//     let start_year = start_date.format('YYYY');
//     let end_month = end_date.format('M');
//     let end_year = end_date.format('YYYY');



//     if(end_date.isAfter(data.start_date)){
//         event_date = start_date.format('MMM DD')

        
//         if(start_month === end_month){ //If same month e.g March 1 - 4, 2022
//             event_date += connector + end_date.format('DD')
//         }else{
//             event_date += connector + end_date.format('MMM DD')
//         }

//         //If same year drop the year for start date
//         if(start_year !== end_year){
//             event_date += ', ' + end_date.format('YYYY')
//         }
//     }else{
//         event_date =start_date.format('ddd, MMM DD')
//     }

//     if(start_time == '00:00:00' && end_time == '23:59:59'){
//         event_date += seperator + ' All Day'
//     }else{
//         event_date += seperator + ' ' + moment(data.start_date + ' ' + data.start_time).format('h:mmA')
//     }

//     return event_date
// }

// function displayDateOnly(data){
//     let connector = '-'
//     let seperator = ','
//     let event_date = '';

//     let start_date = moment(data.start_date);
//     let end_date = moment(data.end_date);

//     let start_month = start_date.format('M');
//     let start_year = start_date.format('YYYY');
//     let end_month = end_date.format('M');
//     let end_year = end_date.format('YYYY');


//     if(end_date.isAfter(data.start_date)){
//         event_date = start_date.format('MMM DD')

        
//         if(start_month === end_month){ //If same month e.g March 1 - 4, 2022
//             event_date += connector + end_date.format('DD')
//         }else{
//             event_date += connector + end_date.format('MMM DD')
//         }

//         //If same year drop the year for start date
//         if(start_year !== end_year){
//             event_date += ', ' + end_date.format('YYYY')
//         }else{
//             event_date += ', ' + start_date.format('YYYY')
//         }
//     }else{
//         event_date =start_date.format('ddd, MMM DD')
//     }

//     return event_date
// }
// function displayTimeOnly(data){
//     let connector = ' - '
//     let seperator = ','
//     let event_date = '';

//     let start_date = moment(data.start_date);
//     let end_date = moment(data.end_date);

    
//     let start_time = data.start_time;
//     let end_time = data.end_time;

//     if(start_time == '00:00:00' && end_time == '23:59:59'){
//         event_date = ' All Day'
//     }
//     else if(end_time == '23:59:59'){
//         event_date = moment(data.start_date + ' ' + data.start_time).format('h:mmA')
//     }
//     else{
//         event_date = moment(data.start_date + ' ' + data.start_time).format('h:mmA') + connector + moment(data.end_date + ' ' + data.end_time).format('h:mmA')
//     }

//     return event_date

// }

// function displayVenue(data){
//     if(data.venue_type == 'physical'){
//         return data.address
//     }else{
//         return 'Online'
//     }
// }

// function registrationStatus(data){
//     let start_date = moment(data.registration_start_date);
//     let end_date = moment(data.registration_end_date);

//     let start_time = moment(data.registration_start_date + ' ' +data.registration_start_time);
//     let end_time = moment(data.registration_end_date + ' ' +data.registration_end_time);

//     const current_date = moment();

//     if(data.status == 'active'){
//         if(start_time.isSameOrBefore(current_date) && end_time.isSameOrAfter(current_date)){
//             return 'open';
//         }
//         else if(current_date.isAfter(end_time)){
//             return 'completed';
//         }
//     }

//     return 'close';
// }

// function displayRegistration(data){
//     const status = registrationStatus(data);

//     if (status === 'open'){
//         return (<><img src={images.starIcon} alt="star" className="star" /> <small className="card-registration-status mx-2">Registration is open</small></>)
//     }
//     else if (data.status === 'postponed' || data.status === 'cancelled'){
//         return (<><img src={images.closeIcon} alt="star" className="star" /><small className="card-registration-status-close mx-2">{`Registration is ${data.status}`}</small></>)  
//     }
//     else {
//         return (<><img src={images.closeIcon} alt="star" className="star" /><small className="card-registration-status-close mx-2">Registration is closed</small></>)  
//     }
// }

// function displayStatus(data){
//     let start_date = moment(data.start_date);
//     let end_date = moment(data.end_date);

//     let start_time = moment(data.start_date + ' ' + data.start_time);
//     let end_time = moment(data.end_date + ' ' + data.end_time);

//     const current_date = moment();

//     if(data.status == 'active'){
//         if(start_time.isSameOrBefore(current_date) && end_time.isSameOrAfter(current_date)){
//             return <div className="event-details"><small>Ongoing</small></div>
//         }else{
//             return <div className="event-details"><small>Active</small></div>
//         }
//     }else if(data.status == 'postponed'){
//         return <div className="event-details"><small>Postponed</small></div>
//     }else if(data.status == 'cancelled'){
//         return <div className="event-details"><small>Cancelled</small></div>
//     }
// }

// function displayPriceLabel(data){

//     if(data.registration_type == 'paid'){
//         let price = Intl.NumberFormat('en-US');

//         return  <small>Starts at PHP {formatNumber(data.registration_price)}</small>
//     }
//     else{
//         return  <small>FREE</small>
//     }
// }


// function displayPrice(data){

//     if(data.registration_type == 'paid'){
//         let price = Intl.NumberFormat('en-US');

//         return  'PHP ' + formatNumber(data.registration_price)
//     }
//     else{
//         return  'FREE'
//     }
// }

// function formatNumber(amount){
//         var valueString=parseFloat(amount).toFixed(2);
//         var formattedString= valueString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//         return formattedString;
    
// }


// /**
//  * 
//  * @returns Autentication
//  */

const getToken = () => {
    if (localStorage.getItem('jma-servicehub-token')) {
        return localStorage.getItem('jma-servicehub-token')
    }

    return false
}

const isAuthenticated = () => {
    if (localStorage.getItem('jma-servicehub-authenticated')) {
        return true
    }

    return false
}

const getUserName = () => {
    if (localStorage.getItem('jma-servicehub-name')) {
        return localStorage.getItem('jma-servicehub-name')
    }

    return false
}

const getUserID = () => {
    if (localStorage.getItem('jma-servicehub-uid')) {
        return localStorage.getItem('jma-servicehub-uid')
    }

    return false
}

const getEmployeeID = () => {
    if (localStorage.getItem('jma-servicehub-eid')) {
        return localStorage.getItem('jma-servicehub-eid')
    }

    return;
}

const getPermissions = () => {
    if (localStorage.getItem('jma-servicehub-permissions')) {
        return localStorage.getItem('jma-servicehub-permissions')
    }

    return []
}

const getUrl = (slug = '') => {
    const url = appConfig.apiUrl + `/${slug}`;

    return url
}

// const displayAdminLink = () => {
//     const permission = getPermissions();

//     if(permission.includes('view-dashboard-events')){
//         return <Link className="login ms-4" to="/admin/dashboard">Admin</Link>
//     }

//     return;
// }

// const getFileURL = (filename) => {
//     if(!filename) return;
//     const api_url = appConfig.apiUrl;

//     return api_url + '/public/events/file/' + filename;
// }

// const uppercaseWords = (str = '') => {

//     //split the above string into an array of strings 
//     //whenever a blank space is encountered

//     const arr = str.split(" ");

//     //loop through each element of the array and capitalize the first letter.


//     for (var i = 0; i < arr.length; i++) {
//         arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

//     }

//     //Join all the elements of the array back into a string 
//     //using a blankspace as a separator 
//     const str2 = arr.join(" ");
    
    
//     return str2
// }



// function displayStatusBadge(status){
//     let bg = '';
//     let text = uppercaseWords(status);
    
//     if(status == 'draft'){
//         bg = 'bg-secondary';
//     }
//     else if(status == 'active'){
//         bg = 'bg-success'
//         text = 'Published'
//     }
//     else if(status == 'cancelled'){
//         bg = 'bg-danger'
//     }
//     else if(status == 'postponed'){
//         bg = 'bg-warning'
//     }
//     else{
//       bg = 'bg-primary';
//     }

//     return (<span className={bg + " text-white px-2 py-1 rounded"} style={{fontSize: '.9rem'}}>{text}</span>)
//   }



// function initFileDragDrop(){
    
//     const $ = window.$;
//     // dragdrop with image preview
//     $(document).ready(() => {
//       // $('.drag-drop-wrap .image-preview').hide();
//       $(".event-banner-img").change(function () {
//           var inputID = $(this).attr('id');

//           const file = this.files[0];
//           if (file) {
//               let reader = new FileReader();
//               reader.onload = function (event) {
//                   $("#img-" + inputID)
//                     .attr("src", event.target.result);

//                   // $(this).parents('.drag-drop-wrap').find(".banner").attr("src", event.target.result);
//                   // $(this).parents('.drag-drop-wrap').find(".banner").addClass('oh');
//               };
//               reader.readAsDataURL(file);

//               $(this).parents('.drag-drop-wrap').addClass('has-item');
//               $(this).parents('.drag-drop-wrap').find('.empty').hide();
//               $(this).parents('.drag-drop-wrap').find('.image-preview').show();
//           }
//       });

//       $(".drag-drop-wrap .btn").click(function(){
//           $(this).parents('.drag-drop-wrap').find('.empty').show();
//           $(this).parents('.drag-drop-wrap').find('.image-preview').hide();
//           $(this).parents('.drag-drop-wrap').find('.event-banner-img').val('');
//           $(this).parents('.drag-drop-wrap').removeClass('has-item');
//       });
//     });
// }

// function displayPaymentStatusBadge(status){
//     let bg = '';
//     //let text = uppercaseWords(status);
//     let text = status;
    
//     if(status == 2){
//         bg = 'bg-warning text-dark';
//         text = 'Pending'
//     }
//     else if(status == 3){
//         bg = 'bg-danger'
//         text = 'Failed'
//     }
//     else if(status == 4){
//         bg = 'bg-success'
//         text = 'Success'
//     }
//     else{
//       bg = 'bg-warning text-dark'
//       text = 'Pending'
//     }

//     return (<span className={bg + " badge"} style={{fontSize: '.8rem'}}>{text}</span>)
// }

// function getFullYear(){
//     const d = new Date();
//     let year = d.getFullYear();

//     return year;
// }

function displayStatusBadge(status = ''){
    let label = '';
    let class_name = ''

    switch (status) {
        case 'created':
            class_name = "badge bg-primary"
            break;
        case 'for-assessment':
            class_name = "badge bg-info"
            break;
        case 'unit-checked':
            class_name = "badge bg-info"
            break;
        case 'pending-quoation':
            class_name = "badge bg-secondary"
            break;
        case 'approved-quoation':
            class_name = "badge bg-info"
            break;
        case 'in-progress':
            class_name = "badge bg-warning"
            break;
        case 'repair-completed':
            class_name = "badge bg-success"
            break;
        case 'for-release':
            class_name = "badge bg-success"
            break;
        case 'released':
            class_name = "badge bg-success"
            break;
        case 'completed':
            class_name = "badge bg-secondary"
            break;
        case 'return-to-owner':
            class_name ="badge bg-danger"
            break;
        case 'cancelled':
            class_name ="badge bg-danger"
            break;
        default:
            return;
    }

    label = <span className={class_name}>{displayStatus(status)}</span>

    return label;
}

function displayStatus(status = ''){
    let label = '';

    switch (status) {
        case 'created':
            label = 'Created'
            break;
        case 'for-assessment':
            label = 'For Assessment'
            break;
        case 'unit-checked':
            label = 'Unit Checked'
            break;
        case 'pending-quoation':
            label = 'Pending Quoation'
            break;
        case 'approved-quoation':
            label = 'Approved Quoation'
            break;
        case 'in-progress':
            label = 'In Progress'
            break;
        case 'repair-completed':
            label = 'Repair Completed'
            break;
        case 'for-release':
            label = 'For Release'
            break;
        case 'released':
            label = 'Unit Released'
            break;
        case 'completed':
            label = 'Completed'
            break;
        case 'return-to-owner':
            label = 'Return To Owner'
            break;
        case 'cancelled':
            label = 'Cancelled'
            break;
        default:
            return;
    }

    return label;
}

function ucwords(str){
    if(!str) return;
    str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });

    return str;
}

function getFormatAmount(amount){
    if(!amount) return '0.00'

    let total = amount;
    var num_parts = amount.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
    total = num_parts.join(".");
    if(!isNaN(total)){
        total = Number(total).toFixed(2)
    }
    total = total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return total;
}

function getFilteredObject(data, filtered_keys = []){
    let result = []
    for (var key in data) {
        const target_data = data[key]
        const filtered = Object.keys(target_data)
                        .filter(key => filtered_keys.includes(key))
                        .reduce((obj, key) => {
                        obj[key] = target_data[key];
                        return obj;
                        }, {});

        result.push(filtered)
    }

    return result
}

function checkPermissions(allowedPermissions) {
    // return allowedPermissions.every(value => {
    //   return user_permissions.includes(value);
    // });
    
    let user_permissions = getPermissions()
    if(!Array.isArray(user_permissions)){
        user_permissions = getPermissions()
        user_permissions = user_permissions.split(',')
    }

    var found = false;
    for (var i = 0; i < allowedPermissions.length; i++) {
        if(user_permissions.includes(allowedPermissions[i])){
            found = true;
            break;
        }
    }

    return found;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const helpers = {
    getToken,
    isAuthenticated,
    getUserName,
    getUserID,
    getPermissions,
    getUrl,
    getEmployeeID,
    displayStatusBadge,
    ucwords,
    getFormatAmount,
    getFilteredObject,
    checkPermissions,
    formatDate
}

export default helpers;