import mainLogo from '../assets/img/jma-electronics-logo.webp';
import iconPhone from '../assets/img/icons/ico-phone.svg';
import iconMail from '../assets/img/icons/ico-mail.svg';
import iconCheck from '../assets/img/icons/ico-check.png';
import iconComment from '../assets/img/icons/ico-comment.svg';
//import donateBanner from '../assets/img/donate-banner.png';
import sslCheckout from '../assets/img/ssl-checkout.png';
import qrCode from '../assets/img/qr-code.jpg';

const images = {
    mainLogo,
    iconPhone,
    iconMail,
    iconCheck,
    iconComment,
    //donateBanner,
    sslCheckout,
    qrCode
}

export default images