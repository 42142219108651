import React, { useEffect, useState } from 'react';
import MainContainer from '../../parts/MainContainer';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import ReactPaginate from 'react-paginate';
import Loader from '../../parts/Loader';
import DropDownSelection from '../../elements/DropDownSelection';
import store from '../../../helpers/store';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import DataTable from 'react-data-table-component';
import moment from 'moment'
 
function ServiceRequestDashboard ({viewer = 'admin', menu = 'service-request-main', page_title = "Service Request Main"}){
    const [service_request_list, setServiceRequest] = useState([]);
    const [all_data, setAllData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [item_list, setItemList] = useState([]);
    const [search_key, setSearchKey] = useState('');
    const [selected_status, setStatus] = useState('');
    const [selected_warranty, setWarranty] = useState('');
    const [selected_page, setPage] = useState(1);

    const [date_range, setDateRange] = useState('');
    const [date_from, setDateFrom] = useState('');
    const [date_to, setDateTo] = useState('');

    const [data_table, setDataTable] = useState('');
    const [selected_per_page, setPerPage] = useState(store.per_page);

    useEffect(() => {
        let technician_id = ''
        if(viewer == 'technician'){
            technician_id = helpers.getEmployeeID();
        }
        //Mounted
        getDataList()
    }, []);

    useEffect(() => {
        let technician_id = ''
        if(viewer == 'technician'){
            technician_id = helpers.getEmployeeID();
        }

        getDataList()
    }, [viewer]);

    function getDataList(condition = {}){
        //page = '', search = '', get_status = '',date_start='',date_end=''
        let filter = '?'

        const page = condition.page ?? ''
        const per_page = condition.per_page ?? selected_per_page
        const search = condition.search ?? search_key
        const get_status = condition.get_status ?? selected_status
        const date_start = condition.date_start ?? date_from
        const date_end = condition.date_end ?? date_to
        const warranty = condition.warranty ?? selected_warranty

        if(page){
            filter += 'page='+page+'&'
        }

        if(per_page){
            filter += 'per_page='+per_page+'&'
        }

        if(search){
            filter += 'search='+search+'&'
        }

        let technician_id = ''
        if(viewer == 'technician'){
            technician_id = helpers.getEmployeeID();
            
            if(technician_id){
                filter += 'technician_id='+technician_id+'&'
            }
        }
    
        const queryString = window.location.search ?? '';
        const urlParams = new URLSearchParams(queryString);
        let param = urlParams.get('status') ?? '';

        if(param){
            setStatus(param)
            filter += 'filter_by=status&filter='+param+'&'
        }

        if(get_status){
            filter += 'filter_by=status&filter='+get_status+'&'
        }

        if(warranty){
            filter += 'warranty='+warranty+'&'
        }

        if(date_start && date_end){
            filter += 'date_from='+date_start+'&'+'date_to='+date_end+'&'
        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('admin/service-request'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                //setServiceRequest(res.data)
                //setAllData(res)
                setLoading(0)
                //table_items(res.data)

                // Datatable
                dataTable(res)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }
    

    function search(e){
        let { value, name } = e.target;
        setSearchKey(value)

        getDataList({
            "page": selected_page,
            "search": value,
            "get_status": selected_status,
            "date_from": date_from,
            "date_to": date_to
        })
        //getDataList(selected_page,value,selected_status,date_from,date_to)
    }

    function filterStatus(e) {
        let { value, name } = e.target;
        //getDataList(selected_page,search_key,value,date_from,date_to)
        getDataList({
            "page": selected_page,
            "search": search_key,
            "get_status": value,
            "date_from": date_from,
            "date_to": date_to
        })

        setStatus(value)
    }

    function filterWarranty(e) {
        let { value, name } = e.target;

        getDataList({
            "page": selected_page,
            "search": search_key,
            "get_status": selected_status,
            "warranty": value,
            "date_from": date_from,
            "date_to": date_to
        })

        setWarranty(value)
    }

    function filterDate(e) {
        let dateFrom = ''
        let dateTo = ''

        if(e){
            setDateRange(e)
            setDateFrom(helpers.formatDate(e[0]))
            setDateTo(helpers.formatDate(e[1]))
            dateFrom = helpers.formatDate(e[0])
            dateTo = helpers.formatDate(e[1])
        }
        else{
            setDateRange('')
            setDateFrom('')
            setDateTo('')
            dateFrom = '';
            dateTo = '';
        }

        //getDataList(selected_page,search_key,selected_status,dateFrom,dateTo)
        getDataList({
            "page": selected_page,
            "search": search_key,
            "get_status": selected_status,
            "date_from": dateFrom,
            "date_to": dateTo
        })
    }

    function table_items(data){
        let table_items = [];
        if (Object.keys(data).length > 0) {
            table_items = Object.entries(data).map(([key, data]) => {
                return (<tr key={data.id}>
                            <td>{data.service_no}</td>
                            <td>{`${data.first_name} ${data.last_name}`}</td>
                            <td>{`${data.appliance_type ? data.appliance_type : '---'} | ${data.brand ? data.brand : '---'}`}</td>
                            <td>{data.model_no ? data.model_no : '---'}</td>
                            <td>{data.updated_at}</td>
                            <td>{data.record_type ? data.record_type : '---'}</td>
                            <td>{data.warranty_status ? data.warranty_status : '---'}</td>
                            <td>{data.branch_name ? data.branch_name : '---'}</td>
                            <td>{helpers.displayStatusBadge(data.status)}</td>
                            <td className='text-center'>
                                <Dropdown className='custom-dropdown'>
                                <Dropdown.Toggle variant="secondary" size="sm" id={"dropdown-actions-"+key}></Dropdown.Toggle>

                                <Dropdown.Menu align="end">
                                    {displayDropItems(data)}
                                </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>)
            })
        }
        else{
            table_items = <tr>
                <td colSpan="9" className='text-center'>No available data</td>
            </tr>
        }

        
        setItemList(table_items)

        //return table_items;
    }

    function dataTable(data){
        let rows = [...data.data]

        const customStyles = {
            rows: {
                style: {
                    minHeight: '35px', // override the row height
                    fontSize: '1rem',
                },
            },
            headCells: {
                style: {
                    paddingLeft: '1rem', // override the cell padding for head cells
                    paddingRight: '1rem',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    minHeight: '30px',
                    borderBottom: '2px solid rgba(0,0,0,.12)'
                },
            },
            cells: {
                style: {
                    paddingLeft: '1rem', // override the cell padding for data cells
                    paddingRight: '1rem',
                },
            },
        };

        const columns = [
            {
                name: 'No',
                selector: data => data.service_no,
                sortable: true,
                maxWidth: '50px',
            },
            {
                name: 'Customer',
                selector: data => data.first_name + ' ' + data.last_name,
                sortable: true,
                minWidth: '180px',
            },
            {
                name: 'Appliance',
                selector: data => (data.appliance_type ? data.appliance_type : '---') + ' | ' + (data.brand ? data.brand : '---'),
                sortable: false,
                minWidth: '200px',
            },
            {
                name: 'Model',
                selector: data => data.model_no ? data.model_no : '---',
                sortable: true,
            },
            {
                name: 'Date Updated',
                selector: data => data.updated_at,
                sortable: true,
                minWidth: '180px',
                format: data => moment(data.updated_at).add(8, 'hours').format('lll')
            },
            {
                name: 'Date Created',
                selector: data => data.created_at,
                sortable: true,
                minWidth: '180px',
                format: data => moment(data.created_at).add(8, 'hours').format('lll')
            },
            {
                name: 'Request Age',
                selector: data => data.request_age < 2 ? data.request_age + ' Day' : data.request_age + ' Days',
                sortable: false,
                minWidth: '100px',
            },
            {
                name: 'Type',
                selector: data => data.record_type ? data.record_type : '---',
                sortable: true,
                minWidth: '100px',
            },
            {
                name: 'Warranty',
                selector: data => data.warranty_status ? data.warranty_status : '---',
                sortable: true,
                minWidth: '100px',
            },
            {
                name: 'Branch',
                selector: data => data.branch_name ? data.branch_name : '---',
                sortable: true,
                minWidth: '180px',
            },
            {
                name: 'Status',
                selector: data => helpers.displayStatusBadge(data.status),
                sortable: false,
            },
            {
                name: '',
                selector: data => data.actions,
                sortable: false,
                maxWidth: '80px',
                cell: row =>   <Dropdown className='custom-dropdown'>
                                    <Dropdown.Toggle variant="outline-primary" size="xs" id={"dropdown-actions-"+row.id}>Action</Dropdown.Toggle>

                                    <Dropdown.Menu align="end">
                                        {displayDropItems(row)}
                                    </Dropdown.Menu>
                                </Dropdown>
            },
        ];

		const table = <DataTable
                            columns={columns}
                            data={rows}
                            defaultSortFieldId={0}
                            pagination
                            customStyles={customStyles} 
                            fixedHeader
                            paginationPerPage={data.per_page}
                            paginationTotalRows={data.total}
                            paginationServer 
                            onChangeRowsPerPage={handlePerRowsChange} 
                            onChangePage={handlePageChange}
                            dense 
                        />

        setDataTable(table)
    }

    const handlePageChange = page => {
        getDataList({"page": page})
      };
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage)
        getDataList({"page": page, "per_page": newPerPage})
    };

    function updateStatus(service_id, status, visibility = 'private'){
        let title = ''
        let text = 'Are you sure you want to continue?'

        if(status === 'in-progress'){
            title = "Start Service Repair"
        }
        else if(status === 'repair-completed'){
            title = "Completed Repair"
        }
        else if(status === 'for-release'){
            title = "Ready for Release"
        }
        else if(status === 'completed'){
            title = "Released Unit"
        }
        else if(status === 'return-to-owner'){
            title = "Return to Owner"
            text = 'This action cannot be reversible. Are you sure you want to continue?'
        }
        else if(status === 'cancelled'){
            title = "Cancel Service"
            text = 'This action cannot be reversible. Are you sure you want to continue?'
        }

        Swal.fire({
            title: title,
            text: text,
            //icon: "warning",
            confirmButtonText: "OK",
            showCancelButton: true,
            closeOnCancel: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                setLoading(1)

                const url = helpers.getUrl('admin/service-request/'+service_id+'/update-status')
                httpRequest(url, "POST", {
                    postdata: {
                        'status' : status,
                        'visibility': visibility
                    },
                }, true)
                    .then((res) => {
                        setLoading(0)
                        getDataList()
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading(0)
        
                        Swal.fire({
                            title: "Error!",
                            text: err.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    }); 

            }
        })
    }

    function displayDropItems(data){
        let items = []

        items.push(<Dropdown.Item key="view-request" as={Link} to={`/service-request/${data.id}/details`}>View Service Request</Dropdown.Item>)
        
        if(data.status === 'created' || data.status === 'for-assessment' || data.status == 'for-release' || data.status == 'completed' || data.status == 'released'){
            items.push(<Dropdown.Item key="edit-request" as={Link} to={`/service-request/${data.id}/edit`}>Edit Request</Dropdown.Item>)
        }

        if(data.status === 'created'){
            items.push(<Dropdown.Item key="print-service-request" as={Link} to={`/export/service-request/${data.id}/view`}>Print Service Request</Dropdown.Item>)
        }

        if(data.status === 'for-assessment'){
            items.push(<Dropdown.Divider key="divider-1" />)
            items.push(<Dropdown.Item key="edit-schedu;e" as={Link} to={`/service-request/${data.id}/edit-schedule`}>Edit Schedule</Dropdown.Item>)
            items.push(<Dropdown.Item key="add-assessment" as={Link} to={`/service-request/${data.id}/add-assessment`}>Add Assessment</Dropdown.Item>)
        }
        else if(data.status === 'unit-checked'){
            items.push(<Dropdown.Divider key="divider-2" />)
            items.push(<Dropdown.Item key="edit-assessment" as={Link} to={`/service-request/${data.id}/edit-assessment`}>Edit Assessment</Dropdown.Item>)
            items.push(<Dropdown.Item key="start-repair" onClick={(e) => updateStatus(data.id, 'in-progress', 'public')} className='text-info'>Start Repair</Dropdown.Item>)
            items.push(<Dropdown.Item key="view-job-order" as={Link} to={`/export/job-order/${data.id}/view`}>View Job Order</Dropdown.Item>)
        }
        else if(data.status === 'approved-quoation'){
            items.push(<Dropdown.Divider key="divider-3" />)
            items.push(<Dropdown.Item key="edit-assessment" as={Link} to={`/service-request/${data.id}/edit-assessment`}>Edit Assessment</Dropdown.Item>)
            items.push(<Dropdown.Item key="start-repair" onClick={(e) => updateStatus(data.id, 'in-progress', 'public')} className='text-info'>Start Repair</Dropdown.Item>)
        }
        else if(data.status === 'in-progress'){
            items.push(<Dropdown.Divider key="divider-3" />)
            items.push(<Dropdown.Item key="edit-assessment" as={Link} to={`/service-request/${data.id}/edit-assessment`}>Edit Assessment</Dropdown.Item>)
            items.push(<Dropdown.Item key="repair-completed" onClick={(e) => updateStatus(data.id, 'repair-completed', 'public')} className='text-success'>Repair Completed</Dropdown.Item>)
            items.push(<Dropdown.Item key="view-job-order" as={Link} to={`/export/job-order/${data.id}/view`}>View Job Order</Dropdown.Item>)
        }
        else if(data.status === 'repair-completed'){
            items.push(<Dropdown.Divider key="divider-3" />)
            items.push(<Dropdown.Item key="edit-assessment" as={Link} to={`/service-request/${data.id}/edit-assessment`}>Edit Assessment</Dropdown.Item>)
            items.push(<Dropdown.Item key="unit-for-release" onClick={(e) => updateStatus(data.id, 'for-release', 'public')} className='text-info'>Unit For Release</Dropdown.Item>)
            items.push(<Dropdown.Item key="view-job-order" as={Link} to={`/export/job-order/${data.id}/view`}>View Job Order</Dropdown.Item>)
        }
        else if(data.status === 'for-release'){
            items.push(<Dropdown.Divider key="divider-3" />)
            items.push(<Dropdown.Item key="edit-assessment" as={Link} to={`/service-request/${data.id}/edit-assessment`}>Edit Assessment</Dropdown.Item>)
            items.push(<Dropdown.Item key="view-job-order" as={Link} to={`/export/job-order/${data.id}/view`}>View Job Order</Dropdown.Item>)
        }
        else if(data.status === 'created'){
            items.push(<Dropdown.Divider key="divider-4" />)
            items.push(<Dropdown.Item key="add-schedule" as={Link} to={`/service-request/${data.id}/new-schedule`}>Add Schedule</Dropdown.Item>)
        }
        else if(data.status === 'completed'){
            items.push(<Dropdown.Item key="edit-assessment" as={Link} to={`/service-request/${data.id}/edit-assessment`}>Edit Assessment</Dropdown.Item>)
            items.push(<Dropdown.Item key="released-form" as={Link} to={`/export/released-form/${data.id}/view`}>View Released Form</Dropdown.Item>)
            items.push(<Dropdown.Item key="view-job-order" as={Link} to={`/export/job-order/${data.id}/view`}>View Job Order</Dropdown.Item>)
        }

        if(data.status !== 'cancelled' && data.status !== 'return-to-owner' && data.status !== 'completed' && data.status !== 'for-release' && data.status !== 'released'){
            items.push(<Dropdown.Divider key="divider-5" />)
            items.push(<Dropdown.Item key="release-unit" as={Link} to={`/service-request/${data.id}/release`}>Release Unit</Dropdown.Item>)
            items.push(<Dropdown.Item key="return-to-owner" onClick={(e) => updateStatus(data.id, 'return-to-owner', 'public')} className='text-primary'>Return To Owner</Dropdown.Item>)
            items.push(<Dropdown.Item key="cancel" onClick={(e) => updateStatus(data.id, 'cancelled', 'public')} className='text-danger'>Cancel Request</Dropdown.Item>)

        }

        return items;
    }

    // Invoke when user click to request another page.
    const handlePageClick = ({ selected: event }) => {
        getDataList(event + 1, search_key)
        setPage(event + 1)
    };

    function handleExport(type = ''){
        let filter = '?'
        if(search_key){
            filter += 'search='+search_key+'&'
        }

        if(selected_status){
            filter += 'filter_by=status&filter='+selected_status+'&'
        }

        if(date_from && date_to){
            filter += 'date_from='+date_from+'&'+'date_to='+date_to+'&'
        }

        if(type){
            filter += 'type='+type+'&'
        }

        if(selected_warranty){
            filter += 'warranty='+selected_warranty+'&'
        }

        filter = filter.slice(0, -1)

        const url = helpers.getUrl('admin/report/export'+filter)
        
        const today = new Date();
        const month = today.getMonth() + 1;
        const date = today.getFullYear() + '-' + month + '-' + today.getDate() + '_' +  today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
        const file_name = 'export-'+ type + '_' + date + '.xlsx';

        console.log(type)
        
        setLoading(1)
        
        fetch(url, {
            method: 'GET',
            headers: httpAuthHeaders(),
        })
        .then((res) => {
          if(res.ok) {
            return res.blob();
          }
          
          const json = res.json();
          const status_code = res.status;
  
          if (!res.ok) {
              let errorCode = json.error_code ? json.error_code : 'unknown';
              let message = json.message ? json.message : (res.statusText ? res.statusText : 'Request failed');
              let data = json.error ? json.error: '';
              let status = json.status ? json.status: '';
              let error = { data: data, message: message, error_code: errorCode, status_code: status_code, status: status };
  
              if (status_code === 422) error = { ...error, ...json };
  
              return Promise.reject(error);
          }
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.setAttribute("href", url);
            a.download = file_name;
            a.click();
            window.URL.revokeObjectURL(url);
            a.parentNode.removeChild(a);
            setLoading(0);
        })
        .catch(err => {
          Swal.fire("Error", "Export failed", "error");
          setLoading(0);
        });
    }

    return (
        <MainContainer page_title={page_title} menu={menu}>
            <div className="container-fluid" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-lg-2 mb-2">
                                <input type="text" className="form-control" placeholder="Search" onChange={search} />
                            </div>
                            <div className="col-lg-2 mb-2">                                            
                                <DropDownSelection
                                    tagClass="form-control"
                                    name='warranty' 
                                    placeholder='Warranty Status'
                                    value={selected_warranty}  
                                    onChange={filterWarranty}
                                    optionList={store.warranty_status}
                                    optionProps ={{value:'id', label:'name'}}
                                />
                            </div>
                            <div className="col-lg-2 mb-2">                                            
                                <DropDownSelection
                                    tagClass="form-control"
                                    name='status' 
                                    placeholder='Service Status'
                                    value={selected_status}  
                                    onChange={filterStatus}
                                    optionList={store.service_status}
                                    optionProps ={{value:'id', label:'name'}}
                                />
                            </div>
                            <div className="col-lg-3 mb-2">                                            
                                <DateRangePicker onChange={filterDate} value={date_range} format="y-MM-dd" className="daterangepicker-control" />
                            </div>
                            <div className="col-lg-3 text-right mt-3 mt-md-0 d-flex justify-content-end align-items-center">
                                {/* <button className='btn btn-info mr-3 btn-sm' to="/service-request/new" type='button' onClick={handleExport}><i className="fas fa-file-excel mr-2" />Export</button>
                                <button className='btn btn-info mr-3 btn-sm' to="/service-request/new" type='button' onClick={handleExport}><i className="fas fa-file-excel mr-2" />Warranty Records</button> */}
                                
                                <Dropdown className='custom-dropdown mr-3'>
                                    <Dropdown.Toggle variant="info" size="sm">Export</Dropdown.Toggle>

                                    <Dropdown.Menu align="end">
                                        <Dropdown.Item key="add-assessment" onClick={(e) => handleExport('service-request')}><i className="fas fa-file-excel mr-2" />Service Request</Dropdown.Item>
                                        <Dropdown.Item key="add-assessment" onClick={(e) => handleExport('warranty-data')}><i className="fas fa-file-excel mr-2" />Warranty Data</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>


                                {helpers.checkPermissions(["all-access", "service-request-create"]) ? <Link className='btn btn-primary btn-sm' to="/service-request/new"><i className="fas fa-plus mr-2" />New</Link> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 table-responsive">
                        {data_table}

                        {/* <table className="table table-sm table-striped">
                            <thead>
                                <tr>
                                <th>Ref No.</th>
                                <th>Customer Name</th>
                                <th>Item</th>
                                <th>Model</th>
                                <th>Date Updated</th>
                                <th>Type</th>
                                <th>Warranty</th>
                                <th>Branch</th>
                                <th style={{width: 40}}>Status</th>
                                <th style={{width: 30}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item_list}
                            </tbody>
                        </table> */}
                    </div>
                    {/* <div className="card-footer d-flex justify-content-center">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={all_data.last_page}
                            previousLabel="Previous"
                            className="pagination mb-0"
                            pageClassName="page-item"

                            activeClassName="active"

                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                        />
                    </div> */}
                </div>
            </div>
        </MainContainer>
    )
}
 
export default ServiceRequestDashboard;