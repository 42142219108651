import React, { useEffect, useState } from 'react';
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import appConfig from '../../../config/env';
import Swal from 'sweetalert2';
import { Outlet, Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import ReactPaginate from 'react-paginate';
import Loader from '../../parts/Loader';
 
function EmployeeManagement (){
    const [user_list, getUsers] = useState([]);
    const [all_data, setAllData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [item_list, setItemList] = useState([]);
    const [search_key, setSearchKey] = useState('');

    useEffect(() => {
        //Mounted
        getDataList()
    }, []);

    function getDataList(page = '', search_key = ''){
        setLoading(1)

        let filter = '?paginate=1&'
        if(page){
            filter += 'page='+page+'&'
        }
        if(search_key){
            filter += 'search='+search_key+'&'
        }
        filter = filter.slice(0, -1)

        const url = helpers.getUrl('admin/employees'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                getUsers(res.data)
                setAllData(res)
                setLoading(0)
                table_items(res.data)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function search(e){
        let { value, name } = e.target;

        setSearchKey(value)
        getDataList('', value)
    }

    function table_items(data){
        let table_items = [];
        if (Object.keys(data).length > 0) {
            table_items = Object.entries(data).map(([key, data]) => {
                return (
                        <tr key={data.id}>
                            <td>{data.last_name}</td>
                            <td>{data.first_name}</td>
                            <td>{data.position}</td>
                            <td>{data.branch_name}</td>
                            <td>{data.status ? <span className="badge bg-success">Active</span> : <span className="badge bg-secondary">Inactive</span>}</td>
                            <td className='text-center'>
                                <Link className='btn btn-xs btn-primary' title='Update User' to={`/manage/employees/${data.id}/edit`}><i className="fas fa-pencil-alt" /></Link>
                            </td>
                        </tr>
                        )
            })
        }
        else{
            table_items = <tr>
                <td colSpan="7" className='text-center'>No available data</td>
            </tr>
        }

        
        setItemList(table_items)

        //return table_items;
    }

    function displayDropItems(data){
        let items = []

        
        items.push(<Dropdown.Item key="edit" as={Link} to={`/manage/user-account/${data.id}/edit`}>Edit Request</Dropdown.Item>)

        return items;
    }

    // Invoke when user click to request another page.
    const handlePageClick = ({ selected: event }) => {
        getDataList(event + 1, search_key)
    };

    return (
        <MainContainer page_title="Manage Employees" menu="manage-employees">
            <div className="container-fluid" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search" onChange={search} />
                                    <span className="input-group-text" id="basic-addon2"><i className="fas fa-search" /></span>
                                </div>
                            </div>
                            <div className="col-md-4">

                            </div>
                            <div className="col-md-4 text-right mt-3 mt-md-0">
                                <Link className='btn btn-primary mb-0' to="/manage/employees/new"><i className="fas fa-plus mr-2" />Create</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 table-responsive">
                        <table className="table table-sm table-striped">
                        <thead>
                            <tr>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Position</th>
                                <th>Branch</th>
                                <th style={{width: 100}}>Status</th>
                                <th style={{width: 30}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {table_items(service_request_list)} */}
                            {item_list}
                        </tbody>
                        </table>
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={all_data.last_page}
                            previousLabel="Previous"
                            className="pagination mb-0"
                            pageClassName="page-item"

                            activeClassName="active"

                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                        />
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default EmployeeManagement;