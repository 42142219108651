import React, { useEffect, useState } from 'react';
import { Outlet, Link,  useParams  } from "react-router-dom";
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
import InputText from '../../elements/InputText'
import InputTextArea from '../../elements/InputTextArea'
import DropDownSelection from '../../elements/DropDownSelection';
import Radio from '../../elements/InputRadio';
import InputFile from '../../elements/InputFile';
import Checkbox from '../../elements/InputCheckbox';
import Loader from '../../parts/Loader';
import InputNumber from '../../elements/InputNumber';
import RenderErrors from '../../elements/RenderErrors';
import store from '../../../helpers/store';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders, httpFormRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
 
function ServiceScheduleForm ({action = false}){
    
    let { service_id } = useParams();
    const [form_data, setForm] = useState(store.service_schedule);
    const [required_parts, setRequiredParts] = useState([]);
    const [employees, setEmployees] = useState({});
    const [form_title, SetTitle] = useState('');
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [parts_items, setPartsItem] = useState([]);
    const [return_url, setReturnURL] = useState('/service-request');

    const schedule_type = [
                    {
                        "id": "home-service",
                        "text": "Home Service"
                    },
                    {
                        "id": "shop",
                        "text": "Shop"
                    },
                    {
                        "id": "dealer",
                        "text": "Dealer"
                    }
                ]
    const parts_status = [
                    {
                        "id": "available",
                        "text": "Available"
                    },
                    {
                        "id": "pending-down-payment",
                        "text": "Pending Down Payment"
                    },
                    {
                        "id": "ordered",
                        "text": "Ordered"
                    },
                    {
                        "id": "received",
                        "text": "Received"
                    },
                    {
                        "id": "price-inquiry",
                        "text": "Price Inquiry"
                    }
                ]

    useEffect(() => {
        SetTitle('Add Schedule')
        getData(service_id)
        
        if(helpers.checkPermissions(["all-access"])){
            setReturnURL('/service-request')
        }
        else if(helpers.checkPermissions(["technician-dashboard"])){
            setReturnURL('/technician-service-request')
        }
    }, []);

    useEffect(() => {
            displayPartsItem()
    }, [required_parts]);

    

    function inputPartsChange(e, index) {
        let { value, name } = e.target;

        const updated_data = [...required_parts];
        updated_data[index][name] = value;
        setRequiredParts(updated_data)
    }

    function addPartsItem(){
        const updated_data = [...required_parts];

        updated_data.push({
            id: "",
            item_description: "",
            model_no: "",
            quantity: "",
            serial_no: "",
            status: "",
            unit_price: ""    
        })
        setRequiredParts(updated_data)
    }

    function removePartsItem(e, index){
        const updated_data = [...required_parts];
        updated_data.splice(index, 1);
        setRequiredParts(updated_data)
    }

    function displayPartsItem(){
        let table_items = [];
        let total = 0;
        if (Object.keys(required_parts).length > 0) {
            table_items = Object.entries(required_parts).map(([key, data]) => {

                return (<div key={key} className="row border mt-3">
                            <div className="col-md-6 form-group">
                                <InputTextArea
                                    id="item_description" 
                                    name="item_description" 
                                    value={data.item_description}  
                                    tagClass="form-control"
                                    onChange={(e) => inputPartsChange(e, key)}
                                    placeholder="Part Description"
                                    rows={1}
                                    label={<>Item Description<span className='text-danger'>*</span></>}
                                />
                            </div>
                            <div className="col-md-1 form-group">
                                <InputNumber
                                    id="quantity" 
                                    name="quantity" 
                                    value={data.quantity}  
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputPartsChange(e, key)}
                                    placeholder="Quantity"
                                    type="number"
                                    label={<>Qty <span className='text-danger'>*</span></>}
                                />

                            </div>
                            <div className="col-md-2 form-group">
                                <InputNumber
                                    id="unit_price" 
                                    name="unit_price" 
                                    value={data.unit_price}  
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputPartsChange(e, key)}
                                    placeholder="Unit Price"
                                    type="number"
                                    label={<>Unit Price</>}
                                />
                            </div>
                            <div className="col-md-3 form-group">
                                <DropDownSelection
                                    tagClass="form-control"
                                    name='status' 
                                    placeholder='Select Part Status'
                                    label={<>Status<span className='text-danger'>*</span></>}
                                    value={data.status}  
                                    onChange={(e) => inputPartsChange(e, key)}
                                    onFocus=""
                                    optionList={parts_status}
                                    optionProps ={{value:'id', label:'text'}}
                                />
                            </div>
                            <div className="col-12 form-group">
                                <button type="button" className='btn btn-xs btn-danger float-right' onClick={(e) => removePartsItem(e, key)}><i className='fas fa-trash'></i></button>
                            </div>
                        </div>)
            })
        }

        setPartsItem(table_items)
    }

    function getEmployee(service_id){
        const url = helpers.getUrl('admin/employees')

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setEmployees(res.data)
                setLoading(0)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function getData(service_id){
        const url = helpers.getUrl('admin/service-request/'+service_id+'/schedule')
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {

                if(res.data){
                    const form_key =[
                        'service_id', 
                        'technician_id',
                        'extra_info',
                        'schedule_type',
                        'assessment',
                        'schedule_date',
                        'assessed_date',
                        'job_description',
                        'dealer',
                        'labor_fee'
                    ]

                    const filtered = Object.keys(res.data)
                                    .filter(key => form_key.includes(key))
                                    .reduce((obj, key) => {
                                    obj[key] = res.data[key];
                                    return obj;
                                    }, {});
                    setForm({ ...form_data, ...filtered })
                    

                    if(res.data.hasOwnProperty("required_parts")){
                        const filter_keys =[
                            'id', 
                            'item_description',
                            'model_no',
                            'quantity',
                            'serial_no',
                            'status',
                            'unit_price'
                        ]
                        const result = helpers.getFilteredObject(res.data.required_parts, filter_keys)
                        setRequiredParts(result)
                    }

                    let title = '';
                    if(action == 'update-schedule'){
                        title = 'Update Schedule:'
                    }
                    else if(action == 'create-assessment'){
                        title = 'Add Assessment: '
                    }
                    else if(action == 'update-assessment'){
                        title = 'Edit Assessment: '
                    }

                    SetTitle(`${title} ${res.data.service_no}`)
                }
                
                getEmployee()
            })
            .catch((err) => {
                getEmployee()

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }

    

    function getFormData(status = 'draft'){
        let form = new FormData();

        for(var key in form_data) {
            form.append(key, form_data[key]);
        }

        return form
    }

    function getFileName(key = ''){
        let filename = ''
        if(form_data[key]){
            filename = form_data[key].name
        }

        return filename
    }

    function displayImage($url = '', $alt =''){
        if($url && service_id){
            return <><img src={$url} width={50} className='img-thumbnail' alt={$alt}/><br/><small>Preview</small></>
        }
    }


    function submit(e){
        //const formData = getFormData()
        const formData = {...form_data, "required_parts" : required_parts}

        let url = ''

        if(action == 'create-schedule'){
            url = helpers.getUrl('admin/service-request/'+service_id+'/create-schedule')
        }
        else if(action == 'update-schedule'){
            url = helpers.getUrl('admin/service-request/'+service_id+'/update-schedule')
        }
        else if(action == 'create-assessment'){
            url = helpers.getUrl('admin/service-request/'+service_id+'/create-assessment')
        }
        else if(action == 'update-assessment'){
            url = helpers.getUrl('admin/service-request/'+service_id+'/update-assessment')
        }

        setLoading(1)

        httpRequest(url, "POST", {
            postdata: formData,
        }, true)
            .then((res) => {
                setLoading(0)
                setErrors([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                // .then((result) => {
                //     if (result.isConfirmed) {
                //         window.location.reload(false);
                //     }
                // })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }
                
                // //Unauthorize
                // if(err.status_code === 401){
                //     window.location.href = "/";
                // }

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function isWarrantyCheck(status = ''){
        if(status == 'active') return true;

        return false;
    }
    
    return (
        <MainContainer page_title={form_title} menu="service-request-main">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="col-sm-10 col-md-10">
                        <form className="card">
                            <div className="card-body">
                                {
                                    action == 'create-schedule' || action == 'update-schedule' ?
                                    <div className="row">
                                        <div className="col-12 mb-3">Details</div>
                                        <div className="col-md-4 form-group">
                                            <DropDownSelection
                                                tagClass="form-control"
                                                name='schedule_type' 
                                                placeholder='Select Schedule Type'
                                                label={<>Type<span className='text-danger'>*</span></>}
                                                value={form_data.schedule_type}  
                                                onChange={inputChange}
                                                onFocus=""
                                                optionList={schedule_type}
                                                optionProps ={{value:'id', label:'text'}}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="first_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <InputText
                                                id="schedule_date" 
                                                name="schedule_date" 
                                                value={form_data.schedule_date}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Date"
                                                type="date"
                                                label={<>Schedule Date<span className='text-danger'>*</span></>}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="schedule_date" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <DropDownSelection
                                                tagClass="form-control"
                                                name='technician_id' 
                                                placeholder='Select Technician'
                                                value={form_data.technician_id}  
                                                onChange={inputChange}
                                                onFocus=""
                                                optionList={employees}
                                                optionProps ={{value:'id', label:'first_name'}}
                                                label={<>Assign Technician<span className='text-danger'>*</span></>}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="technician_id" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <InputTextArea
                                                id="extra_info" 
                                                name="extra_info" 
                                                value={form_data.extra_info}  
                                                tagClass="form-control"
                                                onChange={inputChange}
                                                placeholder="Enter N/A for none extra info"
                                                rows={1}
                                                label={<>Extra Information<span className='text-danger'>*</span></>}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="extra_info" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        { 
                                        form_data.schedule_type == 'dealer' ?
                                        <div className="col-md-4 form-group">
                                            <InputText
                                                id="dealer" 
                                                name="dealer" 
                                                value={form_data.dealer}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Dealer Name"
                                                type="text"
                                                label={<>Dealer<span className='text-danger'>*</span></>}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="dealer" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div> : ''
                                        }
                                    </div> : ''
                                }
                                {
                                    action == 'create-assessment' || action == 'update-assessment' ?
                                    <div>
                                        <div className="row">
                                            <div className="col-12 mb-3">Details</div>
                                            <div className="col-md-4 form-group">
                                                <InputText
                                                    id="assessed_date" 
                                                    name="assessed_date" 
                                                    value={form_data.assessed_date}
                                                    tagClass="form-control mb-0"
                                                    onChange={inputChange}
                                                    placeholder="Date"
                                                    type="date"
                                                    label={<>Assessment Date<span className='text-danger'>*</span></>}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="assessed_date" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                            <div className="col-12"></div>
                                            <div className="col-md-6 form-group">
                                                <InputTextArea
                                                    id="assessment" 
                                                    name="assessment" 
                                                    value={form_data.assessment}  
                                                    tagClass="form-control"
                                                    onChange={inputChange}
                                                    placeholder="Detail Unit Assessment"
                                                    rows={1}
                                                    label={<>Unit Assessment<span className='text-danger'>*</span></>}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="assessment" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <InputTextArea
                                                    id="job_description" 
                                                    name="job_description" 
                                                    value={form_data.job_description}  
                                                    tagClass="form-control"
                                                    onChange={inputChange}
                                                    placeholder="What need to be fix"
                                                    rows={1}
                                                    label={<>Recommendation<span className='text-danger'>*</span></>}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="job_description" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                            <div className="col-md-4 form-group">
                                                <InputNumber
                                                    id="labor_fee" 
                                                    name="labor_fee" 
                                                    value={form_data.labor_fee}
                                                    tagClass="form-control mb-0"
                                                    onChange={inputChange}
                                                    placeholder="Labor Fee"
                                                    type="number"
                                                    label={<>Labor Fee (PHP)<span className='text-danger'>*</span></>}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="labor_fee" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                            <div className="col-12"><hr/></div>
                                            <div className="col-12">
                                                <button type="button" className='btn btn-sm btn-primary float-right' onClick={addPartsItem}>Add Parts</button>
                                                Required Parts
                                            </div>
                                            <div className="col-12">
                                                {parts_items}
                                            </div>
                                        </div> 
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="card-footer text-right">                       
                                <Link className='mr-3' to={return_url}><i className="fas fa-arrow-left mr-3" />Cancel</Link>
                                <button type='button' className='btn btn-success btn-sm' onClick={submit}><i className="fas fa-save mr-2" />Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default ServiceScheduleForm;