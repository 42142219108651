import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';

import InputText from '../elements/InputText';
import RenderErrors from '../elements/RenderErrors';
import appConfig from '../../config/env'
import { httpRequest, httpFormRequest } from '../../helpers/httpService'
import appSession from '../../helpers/session'
import images from '../../helpers/images';
import Loader from '../parts/Loader';
import ReCAPTCHA from "react-google-recaptcha";
 
function LoginPage (){
    const [errors, setErrors] = useState([]);
    const [login_data, setLogin] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(0);
    const recaptchaRef = useRef(null);
    
    // useEffect(() => {
    //     //Mounted
    // }, []);

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
        }

        let new_state = { [name]: value }

        setLogin({ ...login_data, ...new_state });
    }

    function attemptLogin(type = ''){
        const captcha = recaptchaRef.current.getValue();

        if(!captcha){
            setErrors( {
                recaptcha: ['The recaptcha is required.']
            } );
            return;
        }

        let form_data = {...login_data};
        const url = appConfig.apiUrl + '/login';
        setLoading(1)

        httpRequest(url, "POST", {
            postdata: { 
                ...form_data, 
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const {token, permissions, user} = res.data
                appSession.setSession(token, permissions, user)
                setLoading(0)

                Swal.fire({
                    title: "Success!",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/";
                    }
                })

            })
            .catch((err) => {
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }
      
                Swal.fire({
                    //title: 'Authentication Failed',
                    text: 'Invalid username or password',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
    }

    function removeErrors(e){
        let { name } = e.target;
        let newErrors = {...errors}

        delete newErrors[name];

        setErrors(newErrors);
    }
    
    return (
        <div className='login-page' style={{position: 'relative'}}>
        <Loader isLoading={loading}/>
            <div className="login-box">
            {/* /.login-logo */}
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        Welcome To
                        <h2><b>ServiceHub</b></h2>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        <form action="../../index3.html" method="post">
                            <div className="input-group mb-3">
                                <InputText
                                    id={'email'} 
                                    name={'email'} 
                                    value={login_data.email}
                                    tagClass="form-control"
                                    onChange={inputChange}
                                    //onChange={(e) => setLoginEmail(e.target.value)}
                                    placeholder='Enter your email'
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                
                                <RenderErrors 
                                    errors={errors} 
                                    name="email" 
                                    className="text-danger"
                                    style={{fontSize: '.9rem'}}
                                />
                            </div>
                            <RenderErrors 
                                errors={errors} 
                                name="email" 
                                className="text-danger fs-7"
                            />
                            <div className="input-group mb-3">
                                <InputText
                                    id={'password'} 
                                    name={'password'}
                                    value={login_data.password} 
                                    tagClass="form-control"
                                    type='password'
                                    onChange={inputChange}
                                    //onChange={(e) => setLoginPassword(e.target.value)}
                                    placeholder='Enter your password'
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LdmdwwpAAAAALXqUgq-F9Upm120UUepV-ohMlY9"
                                        onChange={()=>removeErrors({target: {name:'recaptcha'}})}
                                        className='text-center'
                                    />
                                    <RenderErrors 
                                        errors={errors} 
                                        name="recaptcha" 
                                        className="text-danger"
                                        style={{fontSize: '.9rem'}}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    {/* <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember" className='ml-2'>
                                            Remember Me
                                        </label>
                                    </div> */}
                                </div>
                                {/* /.col */}
                                <div className="col-4">
                                    {/* <button type="submit" className="btn btn-primary btn-block">Sign In</button> */}
                                    <button type="button" className="btn btn-primary btn-block"  onClick={(e) => attemptLogin('user')}>Login</button>
                                </div>
                            {/* /.col */}
                            </div>
                        </form>
                        {/* /.social-auth-links */}
                        {/* <p className="mb-1">
                            <a href="forgot-password.html">I forgot my password</a>
                        </p>
                        <p className="mb-0">
                            <a href="register.html" className="text-center">Register a new membership</a>
                        </p> */}
                    </div>
                    <div className="card-footer text-center">
                        <img src={images.mainLogo} alt="Logo" width={200} />
                    </div>
                    {/* /.card-body */}
                </div>
                <div className='login-dev'><small className='mt-1 mt-md-0'>Developed By&nbsp;<a href="https://daroy.net/" target='_new'>DAROY DIGITAL SOLUTION</a></small></div>
            {/* /.card */}
            </div>
        </div>
    )
}
 
export default LoginPage;