import React from 'react';
import images from '../../helpers/images';

function Footer(){
    return (
        <footer className="main-footer d-flex flex-column flex-md-row justify-content-center justify-content-md-between">
        {/* To the right */}
        {/* <div className="float-right d-none d-sm-inline">
            Anything you want
        </div> */}
        {/* Default to the left */}
        <span className='text-center'>&copy; {new Date().getFullYear()} <a href="https://jma-electronics.com">JMA Electronics</a>. All rights reserved.</span>
        <span className='dev-copyrights mt-1 mt-md-0'>Developed By&nbsp;<a href="https://daroy.net/" target='_new'>DAROY DIGITAL SOLUTION</a></span>
        </footer>
    )
}

export default Footer;
