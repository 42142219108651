import React, { useEffect } from 'react';
import ContentHeader from './ContentHeader';
import Header from './Header';
import Footer from './Footer';
import helpers from '../../helpers/helpers';
 
function MainContainer ({page_title='', menu="", breadcrumbs=true, children}){
    
    useEffect(() => {
        //Mounted
    }, []);

    
    return (
        <>
        {
            helpers.isAuthenticated() ? <Header menu={menu} /> : ''
        }

        <div className="content-wrapper">
            <ContentHeader title={page_title} breadcrumbs={breadcrumbs} />
            
            <section className="content">
                {children}
            </section>

        </div>

        <Footer />
        </>
    )
}
 
export default MainContainer;