import React, { useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
 
function ContentHeader ({ title = '', breadcrumbs = true}){
    
    useEffect(() => {
        //Mounted
    }, []);
    
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="mb-0">{title}</h5>
                        </div>

                    {
                        breadcrumbs ? 
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div> : ""
                    }
                    </div>
                </div>
            </section>
        </>
    )
}
 
export default ContentHeader;